const rules = {
  email: [
    v => !!v || 'E-mail is required',
    v => /^([A-Za-z0-9\-_.]){1,}@([A-Za-z0-9\-_.]){1,}\.([a-z]){1,}$/.test(v) || 'E-mail must be valid',
  ],
  basicText: (propName, minLength = 2, maxLength = 255) => [
    v => !!v || `${propName} is required`,
    v => `${v}`.length >= minLength || `${propName} must be a min of ${minLength} characters long`,
    v => `${v}`.length <= maxLength || `${propName} must be a max of ${maxLength} characters long`,
    v => /^([A-Za-z0-9\-_.,&\w !|]){0,512}$/.test(v) || `${propName} must not contain special characters`,
  ],
  basicTextNotReq: (propName, minLength = 2, maxLength = 255) => [
    v => `${v}`.length >= minLength || `${propName} must be a min of ${minLength} characters long`,
    v => `${v}`.length <= maxLength || `${propName} must be a max of ${maxLength} characters long`,
    v => /^([A-Za-z0-9\-_.,&\w !|]){0,512}$/.test(v) || `${propName} must not contain special characters`,
  ],
  basicUri: (propName) => [
    v => !!v || `${propName} is required`,
    v => /^https:\/\/.*$/.test(v) || `${propName} must be a valid https:// url (http urls are not allowed)`,
    v => /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/.test(v) || `${propName} must be a valid url`,
    v => `${v}`.length < 255 || `${propName} is ${256-v.length} characters too long`,
  ],
  basicIntlTel: (propName) => [
    v => !!v || `${propName} is required`,
    v => /^\+.*$/.test(v) || `${propName} must be a valid international telephone number - eg: +27/+1`,
    v => /^\+((?:9[679]|8[035789]|6[789]|5[90]|42|3[578]|2[1-689])|9[0-58]|8[1246]|6[0-6]|5[1-8]|4[013-9]|3[0-469]|2[70]|7|1)(?:\W*\d){0,13}\d$/.test(v) || `${propName} must be a valid number`,
  ],
  basicRequired: (propName) => [
    v => !!v || `${propName} is required`
  ],
  password: [
    v => !!v || 'Password is required',
    v => /((.+||)[A-Z](.+||)){2,}/.test(v) || 'Min 2 uppercase characters required',
    v => /((.+||)[a-z](.+||)){2,}/.test(v) || 'Min 2 lowercase characters required',
    v => /((.+||)[0-9](.+||)){1,}/.test(v) || 'Min 1 numbers required',
    v => /((.+||)[!@#$&*_\-+](.+||)){1,}/.test(v) || 'Min 1 special characters required (!@#$&*)',
    v => `${v}`.length >= 8 || 'Password min length is 8',
  ],
  otp: [
    v => !!v || 'One time pin is required',
    v => /^([0-9]){0,6}$/.test(v) || 'Number based OTP (6 characters)',
    v => `${v}`.length === 6 || 'OTP Length is 6 characters',
  ],
  alwaysErrorMessage: (message) => [
    () => message
  ]
}


export default {
  install(Vue) {
    Vue.prototype.$vrules = rules;
  }
};